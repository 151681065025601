.vwui-alert {
  @apply flex items-start text-primary bg-primary/5 py-3 px-4 font-medium;
  --font-size: 12px;
  --icon-size: 18px;

  font-size: var(--font-size);

  app-icon {
    @apply mr-2;
    --icon-oval-bg: var(--color-primary);
  }

  &.is-warning {
    @apply text-warning bg-warning/5;
    --icon-bg: var(--color-primary);
  }
  &--list-warning {
    @apply mt-2 mx-12 ml-0 mb-0;
  }
}

