ion-modal {
  margin-top: env(safe-area-inset-top);
}

.modal {
  @apply flex fixed top-0 bottom-0 left-0 right-0 items-center justify-center p-2 invisible;
  --opacity: 0;
  --translate-y: 25%;

  z-index: 11;

  &.is-visible {
    @apply visible;

    --opacity: 1;
    --translate-y: 0;
  }
}

.modal-container {
  @apply relative z-10 bg-white w-full overflow-hidden pt-6 rounded-lg;
  opacity: var(--opacity);
  transform: translateY(var(--translate-y));
  transition: opacity .25s ease-in-out, transform .35s ease-in-out;
  will-change: opacity, transform;
  max-width: 480px;

  &__header {
    @apply flex justify-between items-center py-0 px-6;

    &:empty {
      display: none;
    }
  }

  &__content {
    @apply overflow-auto pt-0 px-6 pb-6 text-base;
    max-height: 80vh;
    opacity: var(--opacity);
    transition: opacity .15s ease-in-out;
    -webkit-overflow-scrolling: touch;
  }

  &__actions {
    @apply border-t border-t-neutral-20 w-full;
    height: 64px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);

    &:empty {
      display: none;
    }
  }

  &__close-btn {
    @apply border-0 bg-none;
    --icon-size: theme('fontSize.2xl');
    --icon-fill: rgb(var(--color-icon));
  }
}

.modal-actions {
  @apply flex w-full mt-auto;
  height: 64px;
}

.modal-btn {
  @apply text-text flex flex-grow items-center justify-center p-0 border-0 bg-none text-base;
  transition: opacity .15s;

  &:not(:last-child) {
    @apply border-r border-r-neutral-20;
  }

  &:disabled {
    @apply opacity-50;
  }

  &:not(:disabled) {
    &.is-primary {
      @apply text-primary;
    }

    &:active {
      @apply opacity-75;
    }
  }
}

.modal-title {
  @apply flex m-0 items-center;
  font-size: 18px;

  app-icon {
    margin-right: 12px;
  }
}

.modal-backdrop {
  @apply absolute bg-black/60 top-0 bottom-0 left-0 right-0;
  opacity: var(--opacity);
  transition: opacity .25s ease-in-out;
  will-change: opacity;
}
