.radio {
  @apply inline-flex items-center;

  &__input {
    @apply relative flex items-center justify-center mr-2 border border-primary rounded-full;
    width: 22px;
    height: 22px;

    &::after {
      @apply block scale-0 w-1/2 h-1/2 bg-primary rounded-full;
      content: "";
      transition: all .15s ease-in-out;
    }
  }

  input {
    @apply absolute inline-flex justify-center items-center opacity-0 p-0 h-full w-full;
  }

  &:has(input:checked) {
    .radio__input::after {
      @apply scale-100;
    }
  }

  &:has(input:disabled) {
    .radio__input {
      @apply bg-neutral-10 border-neutral-30;
    }

    .radio__label {
      @apply opacity-40 select-none;
    }
  }
}
