.summary {
    @apply shadow-m border border-neutral-20;
    border-radius: 3px;
    padding: var(--margin-x);
    margin-bottom: var(--margin-x);
}
.summary-description {
    @apply text-neutral-50;
    margin-bottom: var(--margin-x);
}
.summary-remarkanswer {
    margin-top: var(--margin-x);
}
.summary-question {
    &__title {
        @apply mb-2;
    }
    &__answer {
        @apply bg-neutral-20 rounded-lg font-medium;
        padding: 14px var(--margin-x);
    }
    \& + & { margin-top: var(--margin-x); }
}

.image-grid {
    margin-top: 14px;
}
