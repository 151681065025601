.question {
    --margin-x: 24px;
    margin: var(--margin-x);
}

.question-required {
    @apply text-error;
}

.question-read-only-value {
    @apply mt-2;
}

.question-heading {
    @apply mt-8 mb-4 font-medium;
    --font-size-title: 22px;

    &__chapter {
        @apply text-neutral-40 mb-2;
    }

    &__title {
        font-size: var(--font-size-title);
    }
}

.question-content {
    @apply mt-6 mb-2;
}

.question-description-image {
    @apply w-full shadow-m object-contain bg-black;
    height: 160px;
    border-radius: 3px;
}

.question-type {
    &.with-margin {
        @apply mb-4;
    }

    &.is-disabled {
        .input-label,
        input,
        .checkbox,
        .question-read-only-value {
            @apply text-neutral-50;
        }
    }

    &__read-only {
        @apply text-text text-base;
    }

    .text-danger {
        font-size: 12px;
    }
}

.table-question__table {
    width: 100%;

    td {
        @apply inline-block w-full pb-0 sm:pb-4 sm:table-cell sm:w-auto;

        &:first-child {
            @apply pt-4 sm:pt-0 sm:w-1/4;
        }
    }

    input {
        @apply mb-0;
    }

    .ng-dirty,
    .ng-touched {
        &.ng-invalid {
            @apply text-error border-b border-b-error;
        }
    }

    .text-danger {
        font-size: 0.85rem;
    }
}

.question-type {
    .input-label:has( + .ng-invalid.ng-touched) {
        @apply text-error;
    }
}

input.ng-invalid.ng-touched {
    @apply border-b border-b-error;
}
