.floating-button {
    @apply absolute flex items-center justify-center bg-primary shadow-m z-10 rounded-full;
    --size: 48px;
    --margin: 16px;

    width: var(--size);
    height: var(--size);
    right: var(--margin);
    bottom: var(--margin);
    will-change: transform;
    transition: 0.2s ease-in-out;

    &__icon {
        @apply text-primary pointer-events-none text-2xl;
    }

    &:hover,:active {
        transform: scaleX(1.2) scaleY(1.2);
    }
}
