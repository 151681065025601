$navbar-height: 60px;

.navbar {
    @apply relative border-b border-b-neutral-20 bg-white;
    --height: #{$navbar-height};
    --item-height: calc(var(--height) - 2px);
    --font-size: 16px;

    display: block !important;

    // prevents ion-slide appearing in the background of this navbar
    z-index: 1;

    &__item-container {
        @apply flex justify-around;
        height: var(--item-height);
    }

    &__item {
        @apply inline-block text-neutral-40 cursor-pointer;
        height: var(--item-height);
        line-height: var(--item-height);
        font-size: var(--font-size);
        transition: color 0.25s linear;
        will-change: color;

        @media (max-width: 360px) {
            font-size: 0.85rem;
        }

        &.active {
            @apply text-text;
        }
    }
    &__item-count {
        @apply inline-block align-middle font-medium;
        font-size: 0.7em;
    }

    &__underline {
        @apply relative bg-gradient-to-t from-primary to-primary/75 bottom-0;
        --left: 0px;
        --width: 1px;

        width: var(--width);
        height: 2px;
        transform: translateX(var(--left));
        transition: transform .25s ease-in-out, width .15s ease-in-out;
        will-change: transform, width;
    }
}

.navbar + * {
    @apply block;
    margin-top: $navbar-height;
}
