.searchbar {
  @apply flex flex-col flex-nowrap text-base bg-white;
}

.searchbar__input {
  gap: 8px;
  display: inherit;
}

.searchbar__input-container {
  @apply gap-2 flex-grow flex items-center justify-center p-2 border border-neutral-20 h-12 rounded max-w-full;
}

.searchbar__icon {
  --icon-size: 1.25rem;
  --icon-fill: rgb(var(--color-text));
}

.searchbar__text-input {
  @apply flex-grow w-0 border-0 p-0 bg-transparent h-full;

  &::placeholder {
    @apply text-neutral-50;
  }
}

.searchbar__add-tag-button {
  @apply gap-1 flex items-center text-text border border-text bg-transparent rounded-lg px-2;
  height: 24px;
}

.searchbar__add-tag-button-suffix {
  @media(max-width: 767px) {
    display: none;
  }
}

.searchbar__add-tag-button:focus-visible,
.searchbar__icon-button:focus-visible .searchbar__icon {
  @apply text-primary border-current;
  --icon-fill: theme('colors.primary');
}

.searchbar__tags {
  @apply gap-2 flex mt-2 flex-row flex-wrap;
}

.searchbar__tag {
  @apply inline-flex items-center border border-neutral-20 rounded px-2;
  height: 32px;
}

.searchbar__tag-label {
  @apply mx-2;
  font-size: 14px;
}

.searchbar__icon-button {
  @apply bg-transparent inline-flex items-center;

  &:focus-visible {
    @apply text-primary;
  }
}

.searchbar__action {
  @apply flex items-center m-0 h-full bg-white text-text rounded p-3 border border-neutral-20;
  font-size: 1.25rem;

    &--primary {
        @apply bg-primary text-white border-primary;
    }
}

.searchbar__qr-code-value {
  @apply bg-transparent overflow-hidden overflow-ellipsis whitespace-nowrap text-text border border-text mr-auto rounded-lg px-2;
}
