.create-comment {
    @apply fixed top-0 bottom-0 left-0 right-0 flex flex-col bg-white;
    --translate-y: 100%;
    --opacity: 0;

    z-index: var(--z-index-image-annotation);
    opacity: var(--opacity);
    transform: translateY(var(--translate-y));
    transition: opacity .25s ease-in-out, visibility .15s ease-in-out, transform .25s ease-in-out;
    will-change: opacity, transform, visibility;

    &--visible {
        --icon-size: 28px;
        --translate-y: 0;
        --opacity: 1;
    }
}
