app-icon {
    @apply inline-flex;

    &.is-primary {
        @apply text-primary;
    }

    svg {
        display: block;
        stroke: var(--icon-stroke, transparent);
        fill: currentColor;
        color: inherit;
        width: var(--icon-size, 1em);
        height: var(--icon-size, 1em);
    }
}
