app-login, app-authenticate, app-workspace-select {
    .login {
        @apply flex flex-col justify-center items-center h-full p-6;
    }

    .logo {
        max-height: 20vh;
        margin-bottom: 48px;
    }

    .link {
        @apply inline-block align-top text-neutral-40 mt-8 no-underline px-0 py-2 font-medium leading-8;
        font-size: 0.7rem;
    }
}
