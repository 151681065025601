.toolbar {
    @apply relative flex items-center border-b border-b-neutral-20;
    --height: var(--vwui-toolbar-height);
    --title-font-size: 16px;
    --sub-title-font-size: theme('fontSize.base');
    --toolbar-btn-width: 50px;
    --toolbar-btn-height: var(--vwui-toolbar-height);
    --toolbar-spacing-top: env(safe-area-inset-top, 0);
    --spacing-x: 1.5rem;

    --icon-size: theme('fontSize.xl');
    --icon-fill: rgb(var(--color-icon));

    height: var(--height);
    margin-top: var(--toolbar-spacing-top);
    background-color: inherit;

    app-icon {
        --color: rgb(var(--color-primary));
    }

    &__custom-back-btn {
        --icon-size: 2rem;
    }

    &__title {
        @apply flex flex-grow flex-row flex-nowrap items-center py-0 px-4 my-0 mx-6;
        font-size: var(--title-font-size);

        &.center-text {
            @apply justify-center text-center m-0;
        }

        &.left {
            @apply flex-row;
        }

        app-icon {
            @apply ml-4;
            --icon-fill: currentColor;
        }
    }

    &__title-clamp {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-break: break-word;
        @apply overflow-hidden line-clamp-2;
    }

    &__subtitle {
        @apply block text-neutral-40;
        font-size: var(--sub-title-font-size);
    }

    &__left,
    &__right {
        @apply flex;
        width: var(--toolbar-btn-width);
        height: var(--toolbar-btn-height);
        gap: 1rem;
    }

    &__left {
        @apply pl-5;
    }

    &__right {
        @apply justify-end pr-5;
    }

    /* Generic styling inside toolbar component */
    .logo {
        max-height: calc(var(--vwui-toolbar-height, 80px) - 2rem);
    }

    button {
        @apply bg-none border-0 outline-0 p-0 w-full h-full;
        line-height: 0;
    }

    .logout-img {
        width: 20px;
    }

    ion-back-button {
        @apply relative block;
        width: var(--toolbar-btn-width);
        height: var(--toolbar-btn-height);

        &::part(button) {
            color: var(--icon-fill, currentColor);
        }

        app-icon {
            @apply absolute left-0 top-1/2;
            transform: translateY(-50%);
        }
    }
}
