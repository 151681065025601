.input, input, textarea {
  @apply outline-none bg-white border-b border-b-neutral-20 rounded-none appearance-none w-full py-2 px-0;

  &::placeholder {
    @apply text-neutral-50;
  }

  &:focus {
    @apply border-primary;
  }
}

textarea {
  @apply block text-text text-base;
  min-height: 150px;
}

.input-label {
  @apply block text-primary font-medium text-xs;

  &.ng-invalid {
    @apply text-error border-error;
  }
}
