@use "@ng-select/ng-select/themes/default.theme.css";

.ng-select {
    @apply text-base;
}

.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
    @apply border-neutral-20;
    height: 45px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    @apply flex h-full top-0;
}

ng-select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder {
    @apply text-neutral-50 top-auto p-0;
}

.ng-dropdown-panel.ng-select-bottom,
.ng-select.ng-select-opened > .ng-select-container {
    @apply border-primary;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    @apply bg-primary-light/50;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    @apply bg-primary-light/20;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
    @apply py-0 px-4;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    @apply pl-4;
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    @apply py-3 px-4;
}

.ng-select.ng-touched.ng-invalid .ng-select-container {
    @apply border-error;
}
