.question-type.is-location {
    &:not(:first-child) {
        @apply mt-8;
    }
}

.location-question-field {
    @apply flex items-end;

    .gps-button {
        @apply text-primary shadow-m w-auto px-3;
        height: 40px;

        app-icon {
            @apply align-middle mr-1;
        }
    }

    .gps-button__label {
        @apply leading-none;
    }
}

.gps-location {
    @apply mt-4 rounded;

    button {
        @apply inline-block text-primary bg-white border border-primary/80 p-0 align-top text-center;
        margin: -4px 0 -4px 8px;
        width: 25px;
        height: 25px;

        app-icon {
            @apply m-0 align-middle inline-block;
        }
    }
}
