.sidenav {
    @apply fixed top-0 right-0 h-full translate-x-full bg-white z-50 transition-all duration-300 motion-reduce:transition-none;
    width: 90vw;

    &__backdrop {
        @apply absolute top-0 h-full bg-black/20 invisible opacity-0;
        width: calc(100% + 10vw);
        left: calc(-100% - 10vw);
        transition: inherit;
    }

    &--open {
        @apply translate-x-0;

        .sidenav__backdrop {
            @apply visible opacity-100;
        }

        .modal {
            left: -10vw;
        }
    }

    .scroll-container {
        height: calc(100vh - 80px - 64px); // height minus toolbar and actions
    }
}
