.vwui-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.vwui-list-item {
    @apply relative border-b border-b-neutral-20 outline-0 px-6 py-4 text-base;
    --padding-x: 1.5rem;
    --text-align: left;

    text-align: var(--text-align);

    &:active {
        @apply opacity-50;
    }

    &__title {
        @apply text-base font-normal m-0;
    }

    &__subtitle,
    &__label {
        @apply text-text p-0;
        margin: 1px 0 0;
    }

    &__label {
        @apply font-medium;
        font-size: 13px;
    }

    &__status-icon {
        @apply absolute top-1/2;
        right: calc(2.5 * var(--padding-x));
        transform: translateY(-50%);
        --icon-size: 1.5em;
    }

    &__arrow-right {
        @apply absolute top-1/2;
        right: var(--padding-x);
        transform: translateY(-50%);

        --icon-fill: rgb(var(--color-primary));
        --icon-size: 1.2em;
    }
}
