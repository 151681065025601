.popup {
    overflow-wrap: break-word;

    &__header {
        @apply p-6 bg-neutral-10 text-center border-b border-neutral-20;
    }

    &__content {
        @apply p-6;
    }

    &__footer {
        @apply p-6 flex items-center justify-between gap-2 border-t border-neutral-10;

        button {
            @apply shadow-m;
        }
    }

    &--fullscreen .popup-content-container {
       @apply h-full;
        & > * {
            @apply h-full flex flex-col;

            .popup__header .popup__footer {
                @apply flex-none;
            }
            .popup__content {
                @apply flex-1 overflow-auto;
            }
        }
    }
}
