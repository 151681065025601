app-attachments-overview-menu {
    .vwui-list {
        @apply flex flex-col h-full;
    }

    li {
        @apply flex items-center;
        min-height: 80px;
    }

    a {
        @apply text-text no-underline;
    }

    p {
        @apply px-6 py-4;
    }
}
