.project-map-container {
    @apply fixed w-full h-full;
}

.esri-ui-top-right {
    margin-top: env(safe-area-inset-top);
}

.project-map-back-button {
    @apply flex justify-center items-center absolute text-white bg-primary rounded-full opacity-80 top-3 left-3;
    margin-top: env(safe-area-inset-top);
    --icon-size: 2em;

    height: 44px;
    width: 44px;
}

.esri-view-width-less-than-medium .esri-popup__action-text {
    display: block;
}

.esri-popup__inline-actions-container > .esri-popup__action, .esri-popup__inline-actions-container > .esri-popup__action-toggle {
    max-width: 100% !important;
}
