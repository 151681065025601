@tailwind base;
@import '@arcgis/core/assets/esri/themes/light/main.css';

// TODO: If theres a better to style ionic please go ahead, otherwise the :host selector will be removed if ionic is removed from the project
:root, :host, ::before, ::after {
    --font-family: 'Heebo', sans-serif;
    --color-primary: 255 166 0; // Figma color text/highlight
    --color-primary-light: 255 199 96; // Figma color second gradient color
    --color-icon: 154 165 181;
    --color-success: 54 179 122; // Figma color positive
    --color-warning: 255 171 0; // Figma color caution
    --color-error: 255 86 64; // Figma color negative
    --color-neutral-10: 246 246 247; // Figma neutral 10
    --color-neutral-20: 228 228 230; // Figma neutral 20
    --color-neutral-30: 192 193 198; // Figma neutral 30
    --color-neutral-40: 173 175 182; // Figma neutral 40
    --color-neutral-50: 119 122 133; // Figma neutral 50
    --color-text: 29 34 52; // Figma color text
    --linear-gradient: linear-gradient(79.64deg, rgb(var(--color-primary) / 42.14), rgb(var(--color-primary-light) / 96.89));
    --box-shadow-m: 0px 2px 4px 0px rgba(0, 56, 76, 0.04);
    --box-shadow-l: 0px 8px 16px 0px rgba(0, 56, 76, 0.16);
    --box-shadow-xl: 0px 30px 20px -10px rgba(0, 56, 76, 0.16);
    --color-black: 0 0 0;
    --color-white: 255 255 255;

    /** Ionic styling **/
    --ion-text-color: rgb(var(--color-text));
    --ion-font-family: var(--font-family);
}

body {
    @apply text-text;
    font-family: var(--font-family);
}

@import "ngx-toastr/toastr";
@import '@angular/cdk/overlay-prebuilt.css';

// App styling
@import "styles/styles";
@import "styles/question-page";

// Utilities after rest of styling to make sure they are higher priority
@tailwind components;
@tailwind utilities;
