.actionbar {
    @apply flex items-center relative bg-white shadow-m;

    padding: 1rem 1rem calc(env(safe-area-inset-bottom, 0) + 1rem);

    > * {
        flex: 1;

        &:not(:last-child) {
            @apply mr-2;
        }
    }

    .vwui-btn {
        @apply shadow-m relative;

        &.is-primary:disabled {
            @apply border border-neutral-20;
        }
    }
}
