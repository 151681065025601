.image-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 14px;

    &__btn {
        @apply relative flex flex-col items-center justify-center bg-white border border-neutral-20 shadow-m cursor-pointer text-base py-4 rounded;
        height: 120px;
        color: inherit;
        transition: opacity .15s;

        &:active {
            @apply opacity-75;
        }

        app-icon {
            @apply mb-4;
            --icon-fill: theme('colors.primary');
            --icon-size: 32px;
        }
    }
}
