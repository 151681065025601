app-point-map {
    display: block;
    height: 40vh;
    min-height: 200px;
}

.point-map {
    &,
    &__container {
        height: inherit;
        min-height: inherit;
    }
}
