.image-viewer {
    @apply bg-black flex fixed top-0 bottom-0 left-0 right-0 items-center z-10;
    --translate-y: 100%;
    --opacity: 0;

    opacity: var(--opacity);
    transform: translateY(var(--translate-y));
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out, transform .25s ease-in-out;
    will-change: opacity, transform;

    &.is-visible {
        --translate-y: 0;
        --opacity: 1;
    }

    &__toolbar {
        @apply flex absolute top-0 left-0 right-0 items-center justify-between pointer-events-none px-3;
        height: calc(var(--vwui-toolbar-height) + env(safe-area-inset-top, 0));
        z-index: var(--z-index-image-viewer-toolbar);
    }

    &__content {
        opacity: var(--opacity);
        transition: opacity .15s ease-in-out;
    }

    &__btn {
        @apply flex items-center justify-center border-0 bg-text/80 rounded-full;
        width: 44px;
        height: 44px;
        pointer-events: all;

        --icon-fill: rgb(var(--color-white));
        --icon-size: theme('fontSize.xl');

        &__comment {
            --icon-size: 17px;

            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    &__image {
        @apply m-auto max-h-screen;
        will-change: transform;
    }

    &.hide-content {
        .image-viewer__image {
            display: none;
        }

        .image-viewer__toolbar {
            display: none;
        }
    }
}
