/* heebo-500 - latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: local('Heebo Medium'), local('Heebo-Medium'),
  url('../../assets/fonts/heebo-v4-latin-500.woff2') format('woff2'),
  url('../../assets/fonts/heebo-v4-latin-500.woff') format('woff');
}
/* heebo-regular - latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: local('Heebo'), local('Heebo-Regular'),
  url('../../assets/fonts/heebo-v4-latin-regular.woff2') format('woff2'),
  url('../../assets/fonts/heebo-v4-latin-regular.woff') format('woff');
}
