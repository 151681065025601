:root {
    --media-item-width: 160px;
    --media-item-height: 160px;

    @media (max-width: 375px) {
        --media-item-width: 130px;
        --media-item-height: 130px;
    }
}

.sortable-container {
    @apply relative w-full;

    .drag-handle {
        @apply absolute top-0 left-0 right-0 bottom-0 cursor-move;
    }

    .drag-placeholder {
        @apply opacity-50;
    }
}

.image-grid {
    @apply relative grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--media-item-width), 1fr));
    grid-gap: 8px;

    @media(max-width: 375px) {
        grid-template-columns: repeat(auto-fill, minmax(var(--media-item-width), 1fr));
    }

    &__item {
        @apply relative text-text bg-white w-full shadow-m border border-neutral-20 text-base rounded;
        height: 160px;

        &--add {
            @apply flex flex-col items-center justify-center;
            grid-row: 1;
            grid-column: 1;

            app-icon {
                --icon-size: 28px;
                margin-bottom: 4px;
            }

            &:disabled {
                @apply text-neutral-50/50;
                --svg-secondary-fill: rgb(var(--color-neutral-50));
            }
        }
    }

    &__image {
        @apply block w-full h-full;
    }
}

.image-thumbnail {
    img {
        @apply w-full h-full object-cover;
    }

    &__icon {
        @apply absolute;
        --icon-size: 30px;

        top: 8px;
        right: 8px;
    }
}
