.accordion-group {
    @apply flex bg-neutral-10 border-b border-b-neutral-10 cursor-pointer py-4 px-6 items-center;
    --title-font-size: theme('fontSize.base');

    &.open {
        @apply bg-primary/30;

        .accordion-group__start-icon {
            @apply border-primary text-primary;

            &.check {
                @apply bg-primary text-white;
            }
        }
    }

    &.collapsed {
        .accordion-group__start-icon.check {
            @apply border border-neutral-50 bg-neutral-50 text-white;
        }
    }

    &__start-icon {
        @apply bg-white flex items-center justify-center mr-4 rounded-full border border-neutral-50;
        width: 14px;
        height: 14px;

        &.indeterminate {
            @apply border-dashed text-neutral-50;
        }

        app-icon {
            @apply flex items-center justify-center;
        }
    }

    &__icon-right {
        @apply ml-auto flex items-center flex-shrink-0 w-6 h-6 rounded;

        &.none {
            width: 18px;
        }

        app-icon {
            --icon-size: 18px;

            margin-right: -12px;
        }
    }

    &__content {
        @apply text-text;
        font-size: var(--title-font-size);
    }
}

.accordion-list {
    @apply overflow-hidden m-0 p-0;
    --item-translate-y: -100%;
    --scale: 0;
    --opacity: 0;
    --height: 0;

    will-change: opacity;
    height: var(--height);

    &.open {
        --item-translate-y: 0;
        --height: auto;
        --opacity: 1;
        --scale: 1;

        .accordion-list__start-icon {
            &.check {
                @apply bg-primary;
            }
        }
    }

    &__item {
        @apply flex w-full text-text border-b border-b-neutral-20 items-center py-4 px-6 text-base;
        transform: translateY(var(--item-translate-y));
        transition: transform .25s ease-in-out;
        will-change: transform;
    }

    &__start-icon {
        @apply flex items-center justify-center bg-white mr-4 rounded-full border border-neutral-50;
        width: 14px;
        height: 14px;

        &.check {
            @apply border-primary;

            app-icon {
                @apply flex items-center justify-center text-white;
            }
        }

        &.indeterminate {
            @apply border-dashed border-primary text-primary;
        }

        &.arrow-right {
            @apply border-none text-primary;
        }

        app-icon {
            @apply flex items-center justify-center;
            width: inherit;
            height: inherit;
        }
    }

    &__icon-right {
        @apply flex flex-shrink-0 items-center mr-2;

        &.none {
            width: 18px;
        }
    }
}
