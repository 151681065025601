.empty-state {
  @apply flex flex-col items-center text-center text-base mt-20 mb-6;

  &__image {
    // Must be important because ".swiper-slide img" selector is more specific
    @apply text-primary mb-8;
    width: 160px !important;
    --icon-size: 100%;
  }
}
