.image-annotation-v2 {
    @apply flex justify-center items-center content-center w-full overflow-hidden fixed top-0 bottom-0 left-0 right-0 bg-black h-full;
    --translate-y: 100%;
    --opacity: 0;

    z-index: var(--z-index-image-annotation);
    opacity: var(--opacity);
    transform: translateY(var(--translate-y));
    transition: opacity .25s ease-in-out, visibility .15s ease-in-out;

    &__container {
        overflow: hidden;
    }

    &--visible {
        --translate-y: 0;
        --opacity: 1;
    }

    &__overlay {
        @apply block absolute h-full top-0 left-0 right-0 pointer-events-none my-0 mx-auto;
        z-index: var(--z-index-image-viewer-toolbar);
        padding: env(safe-area-inset-top, 0) 0 env(safe-area-inset-bottom, 0);
    }

    &__btn {
        @apply inline-block border-0 bg-text/80 items-center justify-center ml-2 mt-2 rounded-full;
        @apply text-white;
        width: 44px;
        height: 44px;
        pointer-events: all;

        --icon-fill: rgb(var(--color-white));
        --icon-size: theme('fontSize.xl');

        &--stroke {
            --icon-stroke: rgb(var(--color-white));
        }

        &--selected {
            @apply bg-white/80;

            app-icon svg {
                --icon-fill: rgb(var(--color-text));
            }
        }

        &-comment-icon {
            @apply relative top-1;
            left: 3px;
        }

        &--left {
            @apply mt-2;
        }

        &-group {
            @apply inline-block absolute text-right right-2;
            width: calc(100% - 80px);
        }

        &-lower-group {
            @apply inline-block absolute right-2;
            bottom: calc(env(safe-area-inset-bottom, 0) + 8px);
        }

    }
}

.modal-body {

    footer {
        display: flex;

        .vwui-btn:first-child {
            @apply mr-5;
        }
    }
}
