.tabular-question {
    @apply mt-4;
}

.tabular-question__row {
    @apply border border-neutral-20 mb-4;
    border-radius: 3px;
}

.tabular-question__row[open] {
    .tabular-question__summary-marker {
        transform: rotate(90deg);
    }

    .tabular-question__summary {
        @apply bg-neutral-10;
    }
}

.tabular-question__summary {
    @apply sticky flex items-center bg-white outline-primary top-0 select-none p-2 pl-4 pr-4 gap-2 font-medium;
    transition: background-color 100ms ease;

    &::marker,
    &::-webkit-details-marker {
        display: none;
    }
}

.tabular-question__summary-marker {
    transition: transform 100ms ease;
}

.tabular-question__summary-action {
    @apply bg-transparent rounded-full ml-auto p-2;
    font-size: inherit;
    line-height: 0;

    &:focus-visible {
        @apply outline-2 outline-primary;
    }

    &:hover {
        @apply text-primary;
    }
}

.tabular-question__content {
    @apply grid gap-4 p-4;
}

.tabular-question__add-row {
    @apply flex justify-center relative;

    &::before {
        @apply absolute bg-neutral-20 w-full left-0 top-1/2;
        content: '';
        height: 1px;
    }
}

.tabular-question__add-button-container {
    @apply relative bg-white p-0 pl-8 pr-8 px-8;
    z-index: 2;
}

.tabular-question__add-button {
    @apply inline-flex p-0 pl-6 pr-6 gap-2;

    &:focus-visible {
        @apply text-primary border-current;
    }
}

.tabular-question__popover {
    @apply bg-white rounded shadow-l;
}

.tabular-question__popover-list {
    @apply m-0 p-2 pl-4 pr-4 list-none;
}

.tabular-question__popover-list-item {
    @apply border-b border-b-neutral-20 py-2;

    &:last-child {
        @apply border-0;
    }
}

.tabular-question__popover-button {
    @apply bg-none w-full h-full text-left text-base;

    &:hover, &:focus-visible {
        @apply text-primary;
    }
}

.tabular-question__modal {
    .text-danger {
        font-size: 12px;
    }

    .ng-invalid {
        input,
        .input-label{
            @apply text-error border-error;
        }
    }
}

input.ng-invalid.ng-touched,
app-question-type-number.ng-touched.ng-invalid input,
app-question-type-number.ng-touched.ng-invalid .input-label,
.tabular-question__row.ng-touched.ng-invalid{
    @apply text-error border-error;
}

.tabular-question__column.ng-touched.ng-invalid {
    .input-label {
        @apply text-error;
    }
}

app-question-type-tabular-modal .modal-body {
    @apply p-6;
}
