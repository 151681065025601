.signature-pad {
    @apply relative shadow-m border border-neutral-20;
    --border-radius: 3px;

    border-radius: 3px;
    height: 200px;

    &__canvas {
        @apply block w-full h-full;
    }

    &__clear {
        @apply flex absolute items-center justify-center text-primary top-0 right-0;
        width: 40px;
        height: 40px;
        border-bottom-left-radius: var(--border-radius);
        opacity: var(--clear-visible, 0);
        transition: opacity .25s;

        --icon-size: 20px;
        --icon-fill: rgb(var(--color-icon));
    }

    &.has-signature {
        --clear-visible: 1;
    }
}

.signature {
    @apply flex relative items-center justify-center shadow-m border border-neutral-20 overflow-hidden rounded p-4 text-base;
    height: 200px;

    &__placeholder {
        @apply flex flex-col items-center;
    }

    &__image {
        @apply w-full h-full object-contain;
    }

    &__disabled {
        @apply absolute bg-neutral-20/60 top-0 bottom-0 left-0 right-0;
    }

    app-icon {
        @apply mb-2;

        --icon-size: 20px;
        --icon-fill: rgb(var(--color-icon));
    }
}
