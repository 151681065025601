app-question-router > * {
  @apply flex flex-col pb-16 overflow-auto;
}

// Make question body fill the remaining space
app-question-body {
  @apply block px-6 flex-grow flex-shrink basis-0 pb-safe-offset-20;
}

app-question-footer {
  @apply fixed bottom-0 inset-x-0 z-10;
}
