.qr-code-scanner__add-answer {
  @apply relative w-full flex flex-col items-center justify-center text-text bg-white border border-neutral-20 shadow-m text-base rounded;
  height: 160px;

  app-icon {
    @apply mb-4;
    --icon-size: 48px;
  }
}

.qr-code-scanner__clear-answer {
    @apply text-neutral-40 bg-none;
    font-size: 12px;
}

app-question-type-qr-code-scanner-modal {
  .modal-title {
    @apply p-4;
  }

  .modal-body {
    @apply flex items-center bg-black h-full;
  }
}

#qr-code-scanner {
  @apply w-full;

  video {
    width: 100% !important;
  }
}
