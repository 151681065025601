.project-main {
    @apply flex flex-col h-full;
}

.project-search {
    @apply flex-initial;
}

.no-project {
    @apply flex text-center flex-col flex-auto items-center justify-center m-4;

    &__title {
        @apply mb-4 font-medium;
        font-size: 20px;
    }

    &__description {
        @apply text-neutral-50 text-base;
    }

    &__img {
        @apply mb-5;
        width: 160px;
        --icon-size: 100%;
    }
}

app-project-job-layered {
    @apply flex flex-col flex-nowrap h-full;
}
