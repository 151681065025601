@import "./input";
@import "./font-imports";

a {
  @apply underline;
}

button,
input {
  @apply outline-0;
}
