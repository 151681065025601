.image-tools {
    @apply flex cursor-pointer overflow-auto fixed bg-white border-b border-b-neutral-20 w-full h-full top-0 left-0;
    --translate-x: 100%;
    --opacity: 0;

    z-index: var(--z-index-image-annotation-editing);
    padding: var(--padding-y) var(--padding-x);
    opacity: var(--opacity);
    transform: translateX(var(--translate-x));
    transition: opacity .25s ease-in-out, visibility .15s ease-in-out, transform .25s ease-in-out;

    &--visible {
        --translate-x: 0;
        --opacity: 1;
    }

    &__accordion {
        width: 100%;

        &-header {
            @apply p-6;
            --icon-size: 20px;
            height: 74px;
            box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.37);

            p {
                @apply text-text m-0;
            }

            app-icon {
                @apply text-text align-middle mr-10;
            }
        }

        &__item {
            @apply flex w-full cursor-pointer text-text border-b border-b-neutral-20 p-6;
            font-size: var(--item-font-size);
            height: 74px;

            &:hover {
                @apply bg-neutral-10;
            }

            p {
                margin: 0;
            }
        }

        &__item-icon-left {
            @apply inline-block align-middle;
            width: 30px;
            height: 30px;
            margin-right: 30px;
        }
    }
}
