.text-primary {
    color: rgb(var(--color-primary)) !important;
}

.text-danger {
    color: rgb(var(--color-error)) !important;
}

.text-warning {
    color: rgb(var(--color-warning)) !important;
}

.text-info {
    color: rgb(var(--color-neutral-40)) !important;
}
