.toggle {
    @apply inline-flex relative;

    @supports selector(:has(*)) {
        width: 4.6666666667rem;
        height: 2.4rem;
    }

    @supports selector(:has(*)) {
        &::before {
            @apply absolute bg-neutral-10 cursor-pointer pl-0 w-full h-full;
            content: "";
            border-radius: 3.3333333333rem;
        }

        &::after {
            @apply absolute bg-white rounded-full top-1/2 left-2;
            content: "";
            width: 1.3333333333rem;
            height: 1.3333333333rem;
            transform: translate(0, -50%);
            transition: transform 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            will-change: transform;
        }
    }

    input {
        @apply relative w-full h-full;
        z-index: 1;

        @supports selector(:has(*)) {
            opacity: 0;
        }
    }

    &:has(input:checked) {
        &::before {
            @apply bg-primary;
        }

        &::after {
            transform: translate(calc(200% - 8px), -50%);
        }
    }
}
