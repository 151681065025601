.image-annotation-v3 {
    @apply fixed top-0 bottom-0 left-0 right-0 flex flex-col z-20;
    --translate-y: 100%;
    --opacity: 0;

    opacity: var(--opacity);
    transform: translateY(var(--translate-y));
    transition: opacity .25s ease-in-out, visibility .15s ease-in-out, transform .25s ease-in-out;
    will-change: opacity, transform;
    background: rgba(6, 20, 29, 1);

    &--visible {
        --translate-y: 0;
        --opacity: 1;
    }

    &__header, &__crop-header {
        @apply flex items-center justify-between px-4 gap-4;
        --ios-safe-area-top: env(safe-area-inset-top);

        padding-top: calc(var(--ios-safe-area-top) + var(--padding-y));
        padding-bottom: var(--padding-y);

        &__button {
            @apply text-white;
            transition: all 200ms;
            font-size: 1.1rem;
            height: 2.4rem;

            &:disabled {
                @apply opacity-50 cursor-not-allowed;
            }

            & > svg {
                width: 2rem;
            }
        }
    }

    &__header {
        --padding-y: .5rem;
    }

    &__crop-header {
        --padding-y: 2rem;
    }

    &__container {
        @apply flex flex-grow items-center justify-center overflow-hidden;
    }

    &__tools {
        @apply relative inline-flex items-center p-4 mx-auto gap-4 shrink-0;
    }

    &__tool-button {
        @apply flex items-center justify-center text-white w-16 h-16 border rounded;
        background-color: rgb(6, 20, 29);
        border-color: rgba(27, 39, 48, 1);

        &::before {
            @apply absolute border-l-transparent border-r-transparent bottom-full top-0 invisible opacity-0 -translate-y-full;
            content: "";
            border-left-width: 12px;
            border-right-width: 12px;
            border-top: 3px solid rgba(27, 39, 48, 1);
        }

        &.active {
            @apply border-2;
            border-color: rgba(0, 140, 188, 0.8);
        }
    }

    &__tools-menu {
        @apply flex flex-row flex-wrap gap-4 w-full absolute left-0 rounded p-4 bottom-full opacity-0 invisible;
        background: rgba(27, 39, 48, 1);
        transform: scale(0.75);
        transform-origin: bottom;
        transition: none;

        &.open {
            @apply transform-none opacity-100 visible;
            transition: transform 200ms ease, opacity 200ms ease;

            + .image-annotation-v3__tool-button::before {
                @apply visible opacity-100 transform-none;
                transition: transform 200ms ease, opacity 200ms ease;
            }
        }
    }
}
