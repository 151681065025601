.menu.answer-overview {
    --width: 90vw;
}

.answer-overview__actions {
    @apply p-2 gap-2;

    app-project-job-form-submit-btn, app-project-job-form-submit-btn-v2 {
        flex-grow: 1;
    }

    app-project-job-form-pdf-btn {
        flex-shrink: 0;

        .vwui-btn {
            width: 48px;
        }

        app-icon {
            @apply text-text;
            --icon-size: 2rem;
        }
    }
}
