app-object-list {
    @apply flex flex-col flex-nowrap h-full relative;

    .vwui-list-item__title {
        @apply font-medium;
        padding-bottom: 10px;
    }
    .keypair-table {
        tr {
            td:nth-child(1) {
                @apply text-neutral-40 pr-5;
            }
        }
    }

    ion-toolbar.sc-ion-searchbar-ios-h,
    ion-toolbar .sc-ion-searchbar-ios-h {
        contain: content;
        padding: 12px 0;
    }
}
