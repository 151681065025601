input {
  @apply text-text text-base;

  &::placeholder {
    @apply text-neutral-50;
  }
}

// Hide the X button in the search input
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
