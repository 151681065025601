:root {
  --vwui-toolbar-height: 80px;
}

.h-minus-project-toolbar {
  height: calc(100% - 152px);
}

.h-minus-project-jobs-toolbar {
  height: calc(100vh - 213px);
}
