.image-annotation {
    @apply fixed top-0 bottom-0 left-0 right-0 flex flex-col bg-black;
    --translate-y: 100%;
    --opacity: 0;

    z-index: var(--z-index-image-annotation);
    opacity: var(--opacity);
    transform: translateY(var(--translate-y));
    transition: opacity .25s ease-in-out, visibility .15s ease-in-out, transform .25s ease-in-out;
    will-change: opacity, transform;

    &--visible {
        --translate-y: 0;
        --opacity: 1;
    }

    &__header, &__crop-header {
        @apply flex items-center;
        --ios-safe-area-top: env(safe-area-inset-top);

        padding: calc(var(--ios-safe-area-top) + var(--padding-y)) 0;

        &__button {
            @apply bg-black text-white border-2 border-black flex-grow;
            transition: all 200ms;
            font-size: 1.1rem;
            height: 2.4rem;
            flex: 1;

            &:disabled {
                @apply opacity-50 cursor-not-allowed;
            }

            & > svg {
                width: 2rem;
            }
        }
    }

    &__header {
        --padding-y: .5rem;
    }

    &__crop-header {
        --padding-y: 2rem;
    }

    &__container {
        @apply flex flex-grow items-center justify-center;
    }

    &__footer {
        @apply flex items-center p-2;
    }

    &__tools, &__palette {
        @apply flex flex-grow flex-wrap;
    }

    &__tools {
        &__tool {
            @apply text-white border-2 border-white bg-black rounded-full m-2 p-2 cursor-pointer;
            transition: all 200ms;

            & > svg {
                height: 3rem;
                width: 3rem;
            }

            &--active, &:hover, &:focus {
                @apply text-black bg-white;
            }

            &--active {
                box-shadow: 0 0 .5rem .25rem #fff;
            }
        }
    }

    &__palette {
        @apply ml-auto justify-end;
        max-width: calc(6 * 3rem);

        @media (min-width: 500px) {
            max-width: calc(5 * 3rem);
        }

        &__color {
            @apply bg-white border-2 border-white rounded-full m-2;
            background-color: var(--color);
            height: 2rem;
            width: 2rem;
            transition: all 200ms;

            &--active, &:hover, &:focus {
                @apply border-white;
            }

            &--active {
                box-shadow: 0 0 .5rem .25rem #fff;
            }
        }
    }
}
