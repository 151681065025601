.checkbox {
    @apply inline-flex items-center;

    &__input {
        @apply relative flex items-center justify-center mr-2 border border-primary rounded-sm;
        width: 1.5rem;
        height: 1.5rem;
    }

    input {
        @apply absolute inline-flex justify-center items-center opacity-0 p-0 h-full w-full;
    }

    &__icon {
        @apply scale-0 text-primary;
        transition: all .15s ease-in-out;
    }

    &:has(input:checked) {
        .checkbox__icon {
            --icon-size: 1.5rem;
            @apply scale-100;
        }
    }

    &:has(input:disabled) {
        .checkbox__input {
            @apply bg-neutral-10 border-neutral-30;
        }

        .checkbox__label {
            @apply opacity-40 select-none;
        }
    }
}
