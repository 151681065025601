.vwui-btn {
    @apply flex overflow-hidden text-ellipsis whitespace-nowrap w-full justify-center items-center outline-0 bg-gradient-to-r from-white to-white text-primary border border-neutral-20 text-base font-medium rounded;
    height: 48px;
    transition: opacity .15s;

    &:is(a) {
        @apply no-underline;
    }

    &:active {
        @apply opacity-50;
    }

    &:disabled, &.disabled {
        @apply text-neutral-40 bg-gradient-to-r from-neutral-10 to-neutral-10;
    }

    &.is-primary:not(:disabled):not(.disabled) {
        @apply text-white border border-primary-light from-primary to-primary-light;
    }

    &.is-warning:not(:disabled):not(.disabled) {
        @apply from-warning to-warning/75 border border-transparent text-white;
    }

    &.is-danger:not(:disabled):not(.disabled) {
        @apply text-white border border-transparent from-error to-error/75;
    }

    &.is-danger-outline:not(:disabled):not(.disabled) {
        @apply bg-white text-error border border-error;
    }

    &__relative {
        @apply relative;
    }

    & app-icon {
        --icon-size: 1.5em;
    }
}
